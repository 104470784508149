import React from "react";
import { FaHtml5, FaCss3Alt, FaReact, FaNodeJs, FaGitAlt, FaJava, FaDocker } from "react-icons/fa";
import { SiTailwindcss, SiRedux, SiJavascript, SiExpress, SiGithub } from "react-icons/si";

const Frontend = () => {
    return (
        <div className="skills__container">
            <div className="skills__content">
                <div className="skills__box">
                    {/* First Column */}
                    <div className="skills__group">
                        <SkillItem
                            icon={<FaHtml5 />} 
                            skillName="HTML & CSS" 
                            level="95%" 
                            color="#4caf50"
                        />
                        <SkillItem
                            icon={<SiTailwindcss />} 
                            skillName="Tailwind CSS" 
                            level="85%" 
                            color="#000"
                        />
                        <SkillItem
                            icon={<FaReact />} 
                            skillName="React JS" 
                            level="75%" 
                            color="#4caf50"
                        />
                        <SkillItem
                            icon={<FaNodeJs />} 
                            skillName="Node JS" 
                            level="65%" 
                            color="#000"
                        />
                        <SkillItem
                            icon={<SiRedux />} 
                            skillName="Redux & React-Router" 
                            level="90%" 
                            color="#4caf50"
                        />
                    </div>

                    {/* Second Column */}
                    <div className="skills__group">
                        <SkillItem
                            icon={<SiJavascript />} 
                            skillName="JavaScript" 
                            level="90%" 
                            color="#000"
                        />
                        <SkillItem
                            icon={<SiExpress />} 
                            skillName="Express" 
                            level="50%" 
                            color="#4caf50"
                        />
                        <SkillItem
                            icon={<FaDocker />} 
                            skillName="Docker" 
                            level="75%" 
                            color="#000"
                        />
                        <SkillItem
                            icon={<SiGithub />} 
                            skillName="GitHub" 
                            level="90%" 
                            color="#4caf50"
                        />
                        <SkillItem
                            icon={<FaJava />} 
                            skillName="Java (Data Structures & Algorithms)" 
                            level="70%" 
                            color="#000"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const SkillItem = ({ icon, skillName, level, color }) => {
    return (
        <div className="skills__data">
            <div className="skills__icon">
                {icon}
            </div>
            <div className="skills__info">
                <h3 className="skills__name">{skillName}</h3>
                <div className="skills__progress-bar">
                    <div 
                        className="skills__progress" 
                        style={{ width: level, backgroundColor: color }}
                    ></div>
                </div>
                <span className="skills__level">{level}</span>
            </div>
        </div>
    );
};

export default Frontend;

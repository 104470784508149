import React from 'react';
import { HiOutlineArrowSmRight } from "react-icons/hi";
import { FaGithub } from "react-icons/fa";

const ProjectItems = ({ item }) => {
  return (
    <div className="project__card">
        <img className="project__img" src={item.image} alt={item.title} />
        <h3 className="project__title">{item.title}</h3>

        {/* Description */}
        {item.description && <p className="project__description">{item.description}</p>}

        {/* Button container */}
        <div className="project__buttons">
            {item.liveLink && (
                <a href={item.liveLink} target="_blank" rel="noopener noreferrer" className="project__button">
                    View <HiOutlineArrowSmRight className="project__button-icon" />
                </a>
            )}
            {item.githubLink && (
                <a href={item.githubLink} target="_blank" rel="noopener noreferrer" className="project__button">
                    GitHub <FaGithub className="project__button-icon" />
                </a>
            )}
        </div>
    </div>
  );
};

export default ProjectItems;

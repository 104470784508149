import Expressions from "../../assets/Expressions.png";
import MyTShirt from "../../assets/My-Tshirt.png";
import RazorpayUIClone from "../../assets/Razorpay Clone.png";
import ImageSlider from "../../assets/Image Slider.png";
import MajesticValley from "../../assets/Majestic Indian Valleys.png";
import PasswordGenerator from "../../assets/Password Generator.png";
import ProfileCard from "../../assets/profile card.png";
import Todo from "../../assets/Todo App.png";


export const projectsData = [
    {
        id: 1,
        image: Expressions,
        title: "Expressions",
        category: "Major Projects",
        liveLink: "https://expressions-two.vercel.app/", 
        githubLink: "https://github.com/rohitgautam16/React-Blog-WebApp",
        description: "A full-stack blog platform using React.js and Appwrite with secure authentication, responsive UI, and efficient state management.",
    },
    {
        id: 2,
        image: MyTShirt,
        title: "My T-Shirt",
        category: "Major Projects",
        liveLink: "https://my-tshirt.vercel.app/",
        githubLink: "https://github.com/rohitgautam16/Tshirt-store",
        description: "An AI-powered T-shirt customization platform with 3D modeling, design generation, and exportable designs.",
    },
    {
        id: 3,
        image: RazorpayUIClone,
        title: "Razorpay UI Clone",
        category: "UI Designs",
        liveLink: "https://razorpay-clone-ui-rg.netlify.app/",
        githubLink: "https://github.com/rohitgautam16/Razorpay-Clone-UI",
        description: "A clone of the Razorpay UI built for practice, demonstrating UI design skills with React.",
    },
    {
        id: 4,
        image: ImageSlider,
        title: "Image Slider",
        category: "UI Designs",
        liveLink: "https://avengerslider.netlify.app/",
        githubLink: "https://github.com/rohitgautam16/Image-Slider",
        description: "A responsive image slider with smooth transitions, showcasing marvel heroes.",
    },
    {
        id: 5,
        image: MajesticValley,
        title: "Majestic Valley",
        category: "UI Designs",
        liveLink: "https://indian-valleys.netlify.app/",
        githubLink: "https://github.com/rohitgautam16/Majestic-Indian-Valleys",
        description: "A parallax-scrolling webpage showcasing the beauty of Indian valleys with a smooth scroll effect.",
    },
    {
        id: 6,
        image: PasswordGenerator,
        title: "Password Generator",
        category: "Minor Projects",
        liveLink: "https://custompwdgenerator.netlify.app/",
        githubLink: "https://github.com/rohitgautam16/Password-Generator",
        description: "A password generator tool allowing users to create secure passwords with custom length and character options.",
    },
    {
        id: 7,
        image: ProfileCard,
        title: "Profile Card",
        category: "UI Designs",
        liveLink: "https://rglinks.netlify.app/",
        githubLink: "https://github.com/rohitgautam16/Simple-Profile-Card",
        description: "A personal Profile Card showcasing myself, and ways to connect me in a responsive design.",
    },
    {
        id: 8,
        image: Todo,
        title: "Todo App",
        category: "Minor Projects",
        liveLink: "https://todocontextlocal.netlify.app/",
        githubLink: "https://github.com/rohitgautam16/Todo-Context-Local",
        description: "A simple Todo application with features for adding, editing, and deleting tasks, built using React and Local Storage.",
    }
];


export const projectsNav = [
    {
        name: "All",
    },
    {
        name: "Major Projects",
    },
    {
        name: "Minor Projects",
    },
    {
        name: "UI Designs",
    },
];
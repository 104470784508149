import React from "react";
import { FiTwitter, FiGithub, FiLinkedin} from "react-icons/fi";
import { SiInstagram } from "react-icons/si";

const Social = () => {
    return (
        <div className="home__social">
            <a href="https://x.com/I_amrohitgautam" className="home__social-icon" target="_blank">
                <FiTwitter />
            </a>
            <a href="https://github.com/rohitgautam16" className="home__social-icon" target="_blank">
                <FiGithub />
            </a>
            <a href="https://www.linkedin.com/in/rohitgautam24/" className="home__social-icon" target="_blank">
                <FiLinkedin />
            </a>
            <a href="https://www.instagram.com/dusk_guy_07/" className="home__social-icon" target="_blank" rel="noreferrer" >
                <SiInstagram />
            </a>  
        </div> 
    ); 
}

export default Social;